import axios from "@/core/services/axios";
import router from "@/router/clean";
import isQualified from "@/core/helpers/checkQualification";
import qs from "qs";
import _ from "lodash";

const SET_CURRENT_STEP = "SET_CURRENT_STEP";
const SET_SUBMISSION_STATUS = "SET_SUBMISSION_STATUS";
const SET_SUBMISSION_INFO = "SET_SUBMISSION_INFO";
const SET_INTERMEDIARY_LIST = "SET_INTERMEDIARY_LIST";
const SET_ADVISORS_INFO = "SET_ADVISORS_INFO";
const SET_MATCH_LIST = "SET_MATCH_LIST";
const SET_CURRENT_SUBMISSION_INFO = "SET_CURRENT_SUBMISSION_INFO";
const CLEAN_CURRENT_SUBMISSION = "CLEAN_CURRENT_SUBMISSION";

const state = {
  step: 1,
  status: {
    step: 1,
    view: "",
  },
  submissions: [],
  currentSubmission: {},
  intermediaryList: {
    type: "",
    list: [],
  },
  advisorsInformation: [],
  matchList: [],
};

const mutations = {
  [SET_CURRENT_STEP](state, payload) {
    state.step = payload;
  },
  [SET_SUBMISSION_STATUS](state, payload) {
    state.status = payload;
  },
  [SET_SUBMISSION_INFO](state, payload) {
    state.submissions = payload;
  },
  [SET_CURRENT_SUBMISSION_INFO](state, payload) {
    state.currentSubmission = payload;
  },
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_ADVISORS_INFO](state, payload) {
    state.advisorsInformation = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
  [CLEAN_CURRENT_SUBMISSION](state) {
    state.currentSubmission = {};
  },
};

const actions = {
  createNewSubmission({ commit, dispatch, rootGetters }) {
    const user = rootGetters["user/currentUser"];
    const data = {
      status: {
        step: 1,
        view: "application",
      },
      submission: {
        interest: {},
        company: {},
        lead: {
          email: user.email,
          givenName: user.firstName,
          familyName: user.lastName,
        },
      },
    };
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;

        commit(SET_SUBMISSION_INFO, [submission]);

        dispatch("setCurrentSubmission", submission.submissionId);
      });
  },
  sendApplicationStep({ commit, dispatch }, payload) {
    var status = {};
    if (
      isQualified({
        country: payload.company.baseCountry,
        yearOfIncorporation: payload.company.yearOfIncorporation,
        twoYearsAuditedFinancial: !!payload.interest.twoYearsAuditedFinancial,
        amountOfFinancingSoughtInUSD:
          payload.interest.amountOfFinancingSoughtInUSD,
        finRevenue: payload.interest.finRevenue,
      })
    ) {
      status = { step: 2, view: "onboarding" };
    } else {
      status = {
        step: 2,
        view: "unqualified",
      };
    }

    var data = {
      submission: payload,
      status,
    };
    const currentSubmission = state.currentSubmission;
    delete currentSubmission._id;

    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        platform: "fsd",
        data,
      })
      .then(() => {
        dispatch(
          "messages/showSuccessMessage",
          {
            text: "Thank you for submitting your application!",
            confirmButtonText: "Ok",
          },
          { root: true }
        ).then(() => {
          commit(SET_SUBMISSION_STATUS, status);
          dispatch("setCurrentUserSubmission");
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setCurrentStep({ commit }, payload) {
    commit(SET_CURRENT_STEP, payload);
    const steps = {
      1: "application",
      2: "onboarding",
      3: "review",
      4: "bdr",
      5: "investment",
    };

    if (!payload) {
      if (
        !(
          state.currentSubmission.submissionId ||
          _.get(router, "currentRoute.value.params.submissionId")
        )
      ) {
        return router.push({ name: "investee" });
      }
      return;
    }

    router.push({
      name: steps[payload],
      params: {
        submissionId:
          state.currentSubmission.submissionId ||
          _.get(router, "currentRoute.value.params.submissionId"),
      },
    });
  },
  async setCurrentSubmission({ state, commit, dispatch }, submissionId) {
    const localUserSubmission = state.submissions?.find(
      (submission) => submission.submissionId === submissionId
    );

    if (localUserSubmission) {
      let status = localUserSubmission.data.status;
      commit(SET_SUBMISSION_STATUS, status);
      let submission = localUserSubmission;
      commit(SET_CURRENT_SUBMISSION_INFO, submission);
      dispatch("setCurrentStep", status.step);
      return submission;
    }
    try {
      const userSubmission = await axios.get(
        `/alien/submissions/${submissionId}`
      );
      if (userSubmission?.data?.items?.length) {
        const currentUserSubmission = userSubmission.data.items[0];
        const status = currentUserSubmission.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        const submission = currentUserSubmission;
        commit(SET_CURRENT_SUBMISSION_INFO, submission);
        dispatch("setCurrentStep", status.step);
        return submission;
      } else {
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: "Not user found",
            buttonText: "Try again!",
          },
          { root: true }
        );
      }
    } catch (err) {
      const errorMessage = err.response
        ? err.response.data.message
        : err.message;
      dispatch(
        "messages/showErrorMessage",
        {
          mainText: errorMessage,
          buttonText: "Try again!",
        },
        { root: true }
      );
    }
  },
  setCurrentUserSubmission({ commit, dispatch }) {
    return axios
      .get("/alien/submissions?entity=investee")
      .then((response) => {
        const userSubmissionsInfo = response.data.items;

        commit(SET_SUBMISSION_INFO, userSubmissionsInfo);
        if (userSubmissionsInfo.length === 1) {
          dispatch("setCurrentSubmission", userSubmissionsInfo[0].submissionId);
        } else {
          dispatch("setCurrentStep");
        }
        return userSubmissionsInfo;
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setNewStatus({ dispatch, state, commit }, payload) {
    var data = {
      submission: state.currentSubmission,
      status: payload,
    };
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateSubmission({ dispatch, commit, state }, { submission, status }) {
    var data = {
      submission,
      status,
    };
    const currentSubmission = state.currentSubmission;
    delete currentSubmission._id;
    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_CURRENT_SUBMISSION_INFO, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setIntermediaryList({ commit, dispatch }, payload) {
    return axios
      .get("/alien/available-intermediaries", { params: { type: payload } })
      .then((response) => {
        commit(SET_INTERMEDIARY_LIST, {
          list: response.data.items,
          type: payload,
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    return axios
      .patch("/alien/advisors/" + payload._id, payload)
      .then(() => {
        dispatch("setAdvisorInformation");
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setAdvisorInformation({ commit, state }) {
    const submissionId = state?.currentSubmission?.submissionId;
    return axios
      .get(`/alien/advisors?investeeSubmissionId=${submissionId}`)
      .then((response) => {
        commit(SET_ADVISORS_INFO, response.data.items);
      });
  },
  setMatchList({ state, commit }, payload) {
    const submissionId = state?.currentSubmission?.submissionId;
    return axios
      .get("/alien/matches", {
        params: { investeeSubmissionId: submissionId, status: payload },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", "inProgress");
    });
  },
  cleanCurrentSubmission({ commit }) {
    commit(CLEAN_CURRENT_SUBMISSION);
  },
};

const getters = {
  submissionsList: (state) => state.submissions,
  currentStep: (state) => state.step,
  submission: (state) => state.currentSubmission?.data?.submission,
  currentSubmissionId: (state) => state.currentSubmission?.submissionId,
  isStepFinished: (state) => (num) => state.status.step > num,
  view: (state) => state.status.view,
  status: (state) => state.status,
  intermediaryListInfo: (state) => state.intermediaryList,
  advisorsInformation: (state) => state.advisorsInformation,
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
