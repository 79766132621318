import { SET_CONTENT, SET_SHOW_BANNER } from "./mutation-types";

const links = {
  faqs: {
    label: "FAQs",
    link: "https://fsduganda.or.ug/investment-readiness/#faqs",
  },
  masterClass: {
    label: "DFF Masterclass",
    link: "https://fsduganda.or.ug/dff-masterclass-series/",
  },
  webpage: {
    label: "Webpage",
    icon: "media/icons/duotune/social/soc009.svg",
    link: "https://fsduganda.or.ug/the-deal-flow-facility/",
  },
  email: {
    label: "Email",
    icon: "media/icons/duotune/communication/com002.svg",
    link: "mailto:dff@fsduganda.or.ug",
  },
  linkedin: {
    label: "Linkedin",
    icon: "media/icons/custom/linkedin.svg",
    link: "https://www.linkedin.com/company/the-deal-flow-facility",
  },
  x: {
    label: "X",
    icon: "media/icons/custom/twitter-x.svg",
    link: "https://x.com/DFF_Uganda",
  },
  moreDff: {
    label: "Learn More (About DFF)",
    link: "https://fsduganda.or.ug/investment-readiness/ ",
  },
  theDff: {
    label: "The DFF",
    link: "https://fsduganda.or.ug/the-deal-flow-facility/",
  },
  dffBlogs: {
    label: "Blogs",
    link: "https://fsduganda.or.ug/insights/blogs/",
  },
};

const state = {
  showBanner: true,
  config: {
    socialMediaLinks: [links.webpage, links.email, links.linkedin, links.x],
    quickLinks: [links.masterClass, links.faqs],
    bannerLinks: [links.moreDff, links.theDff, links.masterClass, links.faqs],
    allLinks: links,
  },
};

const mutations = {
  [SET_CONTENT](state, payload) {
    state.config = payload;
  },
  [SET_SHOW_BANNER](state, payload) {
    state.showBanner = payload;
  },
};

const actions = {
  setPlatform({ commit }, payload) {
    commit(SET_CONTENT, payload);
  },
  setShowBanner({ commit }, payload) {
    commit(SET_CONTENT, !!payload);
  },
};

const getters = {
  platformId: (state) => {
    return state.config?.platformId;
  },
  config: (state) => {
    return state.config;
  },
  showBanner: (state) => {
    return state.showBanner;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
